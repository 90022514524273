@use "tailwindcss/base";
@use "tailwindcss/components";
@use "tailwindcss/utilities";
@use "@angular/cdk/overlay-prebuilt.css";
@use "@ng-select/ng-select/themes/default.theme.css";

@font-face {
	font-family: "kenteken";
	font-style: normal;
	font-weight: bold;
	src: url("./assets/fonts/kenteken.ttf");
}

@layer base {
	[type="text"],
	[type="email"],
	[type="url"],
	[type="password"],
	[type="number"],
	[type="date"],
	[type="datetime-local"],
	[type="month"],
	[type="search"],
	[type="tel"],
	[type="time"],
	[type="week"],
	[multiple],
	textarea,
	select {
		@apply w-full rounded-md border border-gray-300 px-3 py-3 text-xs placeholder-gray-400 focus:z-10  focus:border-primary-500 focus:outline-none focus:ring-primary-500 dark:border-night-500 dark:bg-night-700 dark:text-night-400 dark:placeholder-night-200;
	}

	[type="checkbox"],
	[type="radio"] {
		@apply h-4 w-4 rounded border-gray-300 bg-gray-100 text-green-600 focus:ring-2 focus:ring-green-500 dark:border-night-600 dark:bg-night-700 dark:ring-offset-night-800 dark:focus:ring-green-600;
	}
}

@layer components {
	.dropdown-content {
		@apply pointer-events-none scale-95 opacity-0 duration-100 ease-in;
	}

	.dropdown:hover > .dropdown-content {
		@apply pointer-events-auto block scale-100 animate-fade-in-up opacity-100 duration-200;
	}
}

/** Scroll bar **/
@supports selector(::-webkit-scrollbar) {
	.supports-scrollbars\: pr-2 {
		padding-right: 0.5rem;
	}
}

.loader {
	border-top-color: #3490dc; /* Spinner color */
	animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}
